import React from "react"
import SEO from '../components/seo';
import Layout from '../containers/layout/layout'
import Text from '../components/shared/text'
import {BlogWrapper, Content} from "../components/blog/layout-one/blog.stc";


export default () => (
    <Layout headerLayout={2} headerStyle="darkHeader">
        <SEO title="Atlasopen - Terms of Use" description="Terms of Use"/>
        <BlogWrapper>
            <Content>
                <h1>Terms of Use</h1>
                <Text>
                    These Terms of Use (“Terms”) are a legal contract between you and/or the entity or company that you represent (“you” or “your”) and Atlasopen Pty Ltd (“we”, “us” or “Atlasopen”), and govern your use of all the text, data, information, software, graphics, and other content (collectively “Content”) that we and our affiliates may make available to you.
                    <br></br> <br></br>
                    Please read these terms carefully before browsing this website. Using this website indicated that you have both read and accept these terms. You cannot use this website if you do not accept these terms.
                    <br></br> <br></br>
                    We may edit, suspend or discontinue this website and/or the Content at any time and without notifying you. We may also change, update, add or remove provisions of these Terms from time to time.
                    <br></br> <br></br>
                    <h4>Electronic Communications</h4>
                    By using our website, you consent to receive electronic communications from us. These electronic communications may include notices and other information concerning or related to the website. These electronic communications are part of your relationship with us.
                    <br></br> <br></br>
                    <h4>Copyright</h4>
                    Use of any part of or content from this website for commercial purposes of any kind is expressly prohibited. To request permission to use any of the content on this website for commercial purposes, please contact our company at info@atlasopen.com
                    Under no circumstances may any of the content on this website be displayed on either a commercial or personal website without obtaining prior permission. Unauthorized use of any information from this website may violate copyright, trademark and other laws.
                    <br></br> <br></br>
                    <h4>Trademarks</h4>
                    Atlasopen is a registered trademark of Atlasopen Pty Ltd. All other product and company names used or mentioned herein are or may be trademarks or registered of their respective holders. Use of them as such does not imply any affiliation with or endorsement by them.
                    <br></br> <br></br>
                    <h4>Disclaimer</h4>
                    This website and all information contained herein is provided "as is", without warranty of any kind, either express or implied, including but not limited to any implied warranty of merchantability or fitness for a particular purpose.
                    Any content, text, documents, images or related illustrations that are used or published on this website could have inaccuracies or typographical mistakes. Atlasopen Pty Ltd may make changes and/or enhancements in the content of this website at any time, without notification.
                    <br></br> <br></br>
                    <h4>Links to Third Party Sites</h4>
                    Our provision of a link to any other site or location is for your convenience only and does not signify our endorsement of any such other site or location, or its contents. ATLASOPEN WILL NOT ACCEPT LIABILITY FOR ANY INFORMATION, SOFTWARE, OR LINKS FOUND AT ANY OTHER WEBSITE, INTERNET LOCATION, OR SOURCE OF INFORMATION, OR FOR ANY USE OF SUCH INFORMATION.
                    <br></br><br></br>
                    <h4>Limitation of Liability</h4>
                    In no event shall Atlasopen Pty Ltd be liable for any direct, indirect, special or consequential damages, or any other damages whatsover, for any use of or reliance on this website, or any linked or referenced website, including, without limitation, any lost opportunity, lost profits, business interruption, loss of programs or data, even if expressly advised or otherwise aware of the possibility of such damages, whether in action of contract, negligence, tort, or otherwise.
                    <br></br><br></br>
                    <h4>Applicable Law</h4>
                    By using this website, you agree to the laws of Australia, without regard to principles of conflict of laws, will govern these Terms of Use and any dispute of any sort that might arise between you and Atlasopen.
                    <br></br><br></br>
                    <h4>Local Laws</h4>
                    We control and operate this website from our headquarters and the entirety of the website may not be appropriate or available for use in other locations. If you use our website from outside Australia, you are solely responsible for following applicable local laws.
                </Text>
            </Content>
        </BlogWrapper>
    </Layout>
)
